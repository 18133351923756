import readTime from 'reading-time';
import {
  PrismicBlogPageDataBodyBlogRichText,
  PrismicBlogPageFragmentFragment,
} from '../../graphql-types';

export function readingTime(blog: Partial<PrismicBlogPageFragmentFragment>) {
  const contentText = blog.data.body
    .filter(
      (slice): slice is PrismicBlogPageDataBodyBlogRichText =>
        slice.slice_type === 'blog_rich_text',
    )
    .map((slice) => slice.primary.content.text)
    .join('\n');

  const readingTime = readTime(contentText);
  return `${Math.round(readingTime.minutes)} min read`;
}
