import React from 'react';
import { graphql } from 'gatsby';
import { PrismicAuthorFragmentFragment } from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface AuthorsProps {
  authors: PrismicAuthorFragmentFragment[];
}
export default function Authors({ authors }: AuthorsProps) {
  if (!authors.length) {
    return null;
  }

  return (
    <div className={styles.authors}>
      <Images authors={authors} />
      <Names authors={authors} />
    </div>
  );
}

function Images({ authors }: AuthorsProps) {
  return (
    <div className={styles.images}>
      {authors.map((author) => {
        const name = `${author.data.first_name.text} ${author.data.last_name.text}`;
        return (
          <img
            key={author.id}
            alt={name}
            title={name}
            src={author.data.picture.url}
            className={styles.image}
          />
        );
      })}
    </div>
  );
}

function Names({ authors }: AuthorsProps) {
  return (
    <div className={styles.names}>
      {authors.map((author) => {
        const name = `${author.data.first_name.text} ${author.data.last_name.text}`;
        return (
          <span key={author.id} className={styles.name}>
            {name}
          </span>
        );
      })}
    </div>
  );
}

export const query = graphql`
  fragment PrismicAuthorFragment on PrismicAuthor {
    id
    prismicId
    data {
      first_name {
        text
      }
      last_name {
        text
      }
      position {
        text
      }
      picture {
        alt
        url
      }
    }
  }
`;
