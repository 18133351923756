import { graphql, useStaticQuery } from 'gatsby';
import { UseAllPrismicBlogPageQuery } from '@utils/typings/prismic-types';

export function useAllPrismicBlogPage() {
  const query = useStaticQuery<UseAllPrismicBlogPageQuery>(graphql`
    query UseAllPrismicBlogPage {
      allPrismicBlogPage(sort: { fields: data___publish_date, order: DESC }) {
        nodes {
          ...PrismicBlogPageFragment
        }
      }
    }
  `);

  return query.allPrismicBlogPage.nodes;
}
