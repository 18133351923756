import React from 'react';
import { publishDate } from '@utils/publishDate';
import { readingTime } from '@utils/readingTime';
import { PrismicBlogPageFragmentFragment } from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  blogPage: PrismicBlogPageFragmentFragment;
}
export default function Metadata({ blogPage }: Props) {
  return (
    <div className={styles.metadata}>
      <span className={styles.published}>{publishDate(blogPage)}</span>
      <span className={styles.readTime}>{readingTime(blogPage)}</span>
      {blogPage.tags.map((tag) => (
        <span key={tag} className={styles.tag}>
          {tag}
        </span>
      ))}
    </div>
  );
}
