import React, { useState } from 'react';
import clsx from 'clsx';

const signupUrl =
  'https://1tuyflyvjg.execute-api.ap-southeast-2.amazonaws.com/pryMailingListSignup';

enum Form {
  Idle,
  Submitting,
  Success,
  Error,
}

export function SubscribeForm() {
  const [email, setEmail] = useState('');
  const [state, setState] = useState(Form.Idle);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        setState(Form.Submitting);
        const response = await subscribe(email);
        setState(response.ok ? Form.Success : Form.Error);
      }}
      className="flex h-8 space-x-2"
    >
      <input
        type="email"
        value={email}
        placeholder="Enter email address"
        onChange={(e) => {
          setState(Form.Idle);
          setEmail(e.target.value);
        }}
        className={clsx(
          'text-gray-900 text-xs leading-[0.875rem] tracking-[0.01em] p-2',
          'border border-gray-300 hover:border-gray-500 rounded',
        )}
      />
      <button
        type="submit"
        disabled={state === Form.Submitting || state === Form.Success}
        className={clsx(
          'flex items-center justify-center text-blue-500 font-bold text-sm leading-4 w-24',
          'border border-blue-500 rounded p-2 hover:shadow hover:shadow-blue-500/20 active:shadow-none',
          {
            'bg-gray-200 hover:shadow-none': state === Form.Submitting,
            'text-teal-500 border-teal-500': state === Form.Success,
            'text-red-500 border-red-500': state === Form.Error,
          },
        )}
      >
        {formText(state)}
      </button>
    </form>
  );
}

function formText(state: Form) {
  switch (state) {
    case Form.Idle:
      return 'Subscribe';
    case Form.Success:
      return 'Subscribed!';
    case Form.Error:
      return 'Error';
    default:
      return 'Subscribe';
  }
}

function subscribe(email: string) {
  return fetch(signupUrl, {
    method: 'POST',
    body: JSON.stringify({ email }),
  });
}
