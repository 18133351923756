import { useStaticQuery, graphql } from 'gatsby';
import { UseAllPrismicAuthorQuery } from '@utils/typings/prismic-types';

export function useAllPrismicAuthor() {
  const query = useStaticQuery<UseAllPrismicAuthorQuery>(graphql`
    query UseAllPrismicAuthor {
      allPrismicAuthor {
        nodes {
          ...PrismicAuthorFragment
        }
      }
    }
  `);

  return query.allPrismicAuthor.nodes;
}
